(function ($) {
  //loadPosts();

  $(".main-hero__slider").on("init", function (event, slick) {
    setTimeout(() => {
      document.querySelector(".main-hero__slider-progress").classList.add("active");
    }, 50);
  });
  $(".main-hero__slider").on("beforeChange", () => {
    document.querySelector(".main-hero__slider-progress").classList.remove("active");
    setTimeout(() => {
      document.querySelector(".main-hero__slider-progress").classList.add("active");
    }, 50);
  });
  $(".main-hero__slider").slick({
    arrows: false,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 8000,
    appendDots: $("#main-hero-dots")
  });
  $(".trucks-slider__carousel").slick({
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    centerMode: true,
    variableWidth: true,
    asNavFor: ".trucks-slider__text-carousel",
    responsive: [{
      breakpoint: 600,
      settings: {
        variableWidth: false,
        slidesToShow: 1
      }
    }]
  });
  $(".trucks-slider__text-carousel").slick({
    arrows: false,
    dots: false,
    fade: true,
    asNavFor: ".trucks-slider__carousel"
  });
  $(".blog-cards__carousel").slick({
    slidesToShow: 3,
    appendArrows: ".blog-cards__controls",
    centerMode: true,
    centerPadding: "0",
    responsive: [{
      breakpoint: 600,
      settings: {
        //variableWidth: false,
        slidesToShow: 1
      }
    }]
  });
  $(".gallery-slider__carousel ul").on("init", function (event, slick) {
    setTimeout(() => {
      // lightGallery(
      //   document.querySelector(".gallery-slider__carousel .slick-track", {
      //     selector: ".item",
      //     preload: 5,
      //   })
      // );
    }, 50);
  });
  $(".gallery-slider__carousel ul").slick({
    slidesToShow: 4,
    infinite: false,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $("#tabs-gallery-0 ul").slick({
    slidesToShow: 4,
    infinite: false,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(".truck-maintenance__carousel").slick({
    slidesToShow: 2,
    arrows: false,
    dots: true,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(".related-trucks__carousel").slick({
    slidesToShow: 3,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(".fancybox").fancybox({
    padding: 0
  });
  $(".related-posts__content").slick({
    slidesToShow: 3,
    appendArrows: ".related-posts",
    centerMode: true,
    centerPadding: "0",
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(".truck-intro__features").slick({
    mobileFirst: true,
    responsive: [{
      breakpoint: 640,
      settings: "unslick"
    }]
  });
  $(".category-items__carousel--0").slick({
    slidesToShow: 3,
    appendArrows: ".category-items",
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);
const menu_item_with_child = document.querySelectorAll(".header__nav li.has-children");
if (menu_item_with_child) {
  for (let m = 0; m < menu_item_with_child.length; m++) {
    menu_item_with_child[m].addEventListener("mouseover", () => {
      const top = window.scrollY;
      if (top <= 80) {
        document.getElementById("header").classList.add("scroll");
        headerLogo.setAttribute("src", logoData);
      }
    });
    menu_item_with_child[m].addEventListener("mouseleave", () => {
      const top = window.scrollY;
      if (top <= 80) {
        document.getElementById("header").classList.remove("scroll");
        headerLogo.setAttribute("src", logoSrc);
      }
    });
    // menu_item_with_child[m]
    //   .querySelector(".open-subnav")
    //   .addEventListener("click", () => {
    //     for (let x = 0; x < menu_item_with_child.length; x++) {
    //       if (x !== m) {
    //         menu_item_with_child[x].classList.remove("subnav-open");
    //       }
    //     }
    //     if (menu_item_with_child[m].classList.contains("subnav-open")) {
    //       menu_item_with_child[m].classList.remove("subnav-open");
    //     } else {
    //       menu_item_with_child[m].classList.add("subnav-open");
    //     }
    //   });
  }
}
const open_search = document.getElementById("open-search");
if (open_search) {
  open_search.addEventListener("click", () => {
    document.querySelector(".search-form").classList.add("open");
  });
}
const close_search = document.getElementById("close-search");
if (close_search) {
  close_search.addEventListener("click", () => {
    document.querySelector(".search-form").classList.remove("open");
  });
}
const accordItems = document.getElementsByClassName("history-accordion__item");
if (accordItems) {
  jQuery(".history-accordion__item.open .history-accordion__slider").slick();
  for (let i = 0; i < accordItems.length; i++) {
    const cardButton = accordItems[i].querySelector(".history-accordion__button");
    cardButton.addEventListener("click", () => {
      if (accordItems[i].classList.contains("open")) {
        accordItems[i].classList.remove("open");
        jQuery(accordItems[i]).find(".history-accordion__slider").slick("unslick");
      } else {
        accordItems[i].classList.add("open");
        jQuery(accordItems[i]).find(".history-accordion__slider").slick();
      }
    });
  }
}
const spare_nav = document.querySelectorAll(".spare-tabs__nav a");
const spare_tabs = document.querySelectorAll(".spare-tabs__content .tab");
const spare_select = document.querySelector(".spare-tabs__select select");
const tabs_gallery = document.querySelectorAll(".gallery-slider__carousel--tabs");
if (spare_nav) {
  for (let l = 0; l < spare_nav.length; l++) {
    spare_nav[l].addEventListener("click", event => {
      event.preventDefault();
      for (let lt = 0; lt < spare_tabs.length; lt++) {
        spare_tabs[lt].classList.remove("active");
      }
      spare_tabs[l].classList.add("active");
      for (let m = 0; m < spare_nav.length; m++) {
        spare_nav[m].classList.remove("active");
      }
      spare_nav[l].classList.add("active");
      for (let g = 0; g < tabs_gallery.length; g++) {
        tabs_gallery[g].classList.remove("active");
        jQuery(accordItems[g]).find("ul").slick("unslick");
      }
      tabs_gallery[l].classList.add("active");
      jQuery(tabs_gallery[l]).find("ul").slick({
        slidesToShow: 4,
        infinite: false,
        responsive: [{
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }]
      });
    });
  }
}
if (spare_select) {
  spare_select.addEventListener("change", event => {
    const select_value = spare_select.value;
    for (let lt = 0; lt < spare_tabs.length; lt++) {
      spare_tabs[lt].classList.remove("active");
    }
    spare_tabs[select_value].classList.add("active");
    for (let m = 0; m < spare_nav.length; m++) {
      spare_nav[m].classList.remove("active");
    }
    spare_nav[select_value].classList.add("active");
    for (let g = 0; g < tabs_gallery.length; g++) {
      tabs_gallery[g].classList.remove("active");
      jQuery(accordItems[g]).find("ul").slick("unslick");
    }
    tabs_gallery[select_value].classList.add("active");
    jQuery(tabs_gallery[select_value]).find("ul").slick({
      slidesToShow: 4,
      infinite: false,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  });
}
const dealer_select = document.getElementById("dealer-select");
const dealer_items = document.querySelectorAll(".dealer");
if (dealer_select) {
  dealer_select.addEventListener("change", () => {
    const select_value = dealer_select.value;
    for (let d = 0; d < dealer_items.length; d++) {
      dealer_items[d].classList.remove("active");
    }
    document.getElementById(select_value).classList.add("active");
  });
}
const share_links = document.querySelectorAll(".share-links a");
if (share_links) {
  for (let s = 0; s < share_links.length; s++) {
    share_links[s].addEventListener("click", event => {
      if (share_links[s].classList.contains("popup-share")) {
        event.preventDefault();
        const thisHref = share_links[s].href;
        window.open(thisHref, "newwindow", "width=460, height=500, top=100, left=100");
      }
    });
  }
}
const category_select = document.getElementById("category-select");
const category_items = document.querySelectorAll(".category-items__carousel");
if (category_select) {
  category_select.addEventListener("change", () => {
    const select_value = category_select.value;
    for (let d = 0; d < category_items.length; d++) {
      jQuery(`.category-items__carousel.active`).slick("unslick");
      category_items[d].classList.remove("active");
    }
    document.getElementById(select_value).classList.add("active");
    jQuery(`#${select_value}`).slick({
      slidesToShow: 3,
      appendArrows: ".category-items",
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  });
}
const windowWidth = window.innerWidth;
if (windowWidth < 900) {
  headerLogo.setAttribute("src", logoData);
  const menu_nav = document.querySelectorAll(".menu-item.has-children");
  if (menu_nav) {
    for (let m = 0; m < menu_nav.length; m++) {
      let arrowButton = `<span class="arrow-button"></span>`;
      menu_nav[m].innerHTML += arrowButton;
      const directItem = menu_nav[m].querySelector(".arrow-button");
      directItem.addEventListener("click", event => {
        event.preventDefault();
        if (menu_nav[m].classList.contains("active")) {
          menu_nav[m].classList.remove("active");
        } else {
          for (let mr = 0; mr < menu_nav.length; mr++) {
            menu_nav[mr].classList.remove("active");
          }
          menu_nav[m].classList.add("active");
        }
      });
      const subnav1 = menu_nav[m].querySelectorAll(".submenu1 > li");
      for (let s = 0; s < subnav1.length; s++) {
        subnav1[s].innerHTML += arrowButton;
        subnav1[s].querySelector(".arrow-button").addEventListener("click", event => {
          event.preventDefault();
          if (subnav1[s].classList.contains("active")) {
            subnav1[s].classList.remove("active");
          } else {
            for (let sr = 0; sr < subnav1.length; sr++) {
              subnav1[sr].classList.remove("active");
            }
            subnav1[s].classList.add("active");
          }
        });
      }
      const subnav2 = menu_nav[m].querySelectorAll(".submenu2 > li");
      for (let s2 = 0; s2 < subnav2.length; s2++) {
        subnav2[s2].innerHTML += arrowButton;
        subnav2[s2].querySelector(".arrow-button").addEventListener("click", event => {
          event.preventDefault();
          if (subnav2[s2].classList.contains("active")) {
            subnav2[s2].classList.remove("active");
            jQuery(subnav2[s2]).find(".submenu3 ul.slick-slider").slick("unslick");
          } else {
            for (let sr2 = 0; sr2 < subnav2.length; sr2++) {
              subnav2[sr2].classList.remove("active");
              jQuery(subnav2[sr2]).find(".submenu3 ul.slick-slider").slick("unslick");
            }
            subnav2[s2].classList.add("active");
            jQuery(subnav2[s2]).find(".submenu3 ul").slick({
              mobileFirst: true,
              responsive: [{
                breakpoint: 900,
                settings: "unslick"
              }]
            });
          }
        });
      }
    }
  }
}
const open_menu = document.getElementById("open-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.add("mobile-open");
  });
}
const close_menu = document.getElementById("close-menu");
if (close_menu) {
  close_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.remove("mobile-open");
  });
}
const search_post_type = document.getElementById("search_post_type");
const search_tabs = document.querySelectorAll(".search-head__nav a");
const inner_search_form = document.getElementById("inner_search_form");
if (search_post_type && search_tabs) {
  for (let t = 0; t < search_tabs.length; t++) {
    search_tabs[t].addEventListener("click", event => {
      event.preventDefault();
      const thisHref = search_tabs[t].getAttribute("href");
      search_post_type.value = thisHref;
      document.getElementById("search_c_page").value = 1;
      inner_search_form.submit();
    });
  }
}
const search_form_order = document.getElementById("search_form_order");
if (search_form_order) {
  search_form_order.addEventListener("change", () => {
    inner_search_form.submit();
  });
}

// const search_items_per_page = document.getElementById("search_items_per_page");
// search_items_per_page.addEventListener("change", () => {
//   inner_search_form.submit();
// });